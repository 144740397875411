import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Alert, ListGroup, Modal } from "react-bootstrap";

const AddUpdate = () => {
    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [updates, setUpdates] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [editDate, setEditDate] = useState("");
    const [editDescription, setEditDescription] = useState("");

    const fetchUpdates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/updates`);
            const sortedUpdates = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            setUpdates(sortedUpdates);
        } catch (error) {
            setErrorMessage("Error fetching updates. Please try again.");
            console.error("Error:", error);
        }
    };

    const fetchUpdateDetails = async (updateId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/updates/${updateId}`);
            if (response.status === 200) {
                const update = response.data;
                setEditDate(new Date(update.date).toISOString().split("T")[0]); // Format date to YYYY-MM-DD
                setEditDescription(update.description);
                setEditId(updateId); // Track the update being edited
                setShowModal(true); // Show the modal with the fetched data
            }
        } catch (error) {
            setErrorMessage("Error loading update details. Please try again.");
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchUpdates();
    }, []);

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        const payload = { date, description };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/updates`, payload);
            if (response.status === 200) {
                setSuccessMessage("Update added successfully.");
                setDate("");
                setDescription("");
                fetchUpdates();
            }
        } catch (error) {
            setErrorMessage("Error adding update. Please try again.");
            console.error("Error:", error);
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const payload = { date: editDate, description: editDescription };
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/updates/${editId}`, payload);
            if (response.status === 200) {
                setSuccessMessage("Update edited successfully.");
                fetchUpdates();
                handleCloseModal(); // Close modal after successful save
            }
        } catch (error) {
            setErrorMessage("Error editing update. Please try again.");
            console.error("Error:", error);
        }
    };

    const handleEdit = (updateId) => {
        fetchUpdateDetails(updateId); // Fetch data for the selected update and then show modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Hide the modal
        setEditDate(""); // Clear the form data
        setEditDescription("");
        setEditId(null); // Reset to add mode
    };

    return (
        <Container className="py-5">
            <h2>Add New Update</h2>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form onSubmit={handleAddSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Add Update
                </Button>
            </Form>

            {/* List of Updates with Edit Option */}
            <h3 className="mt-5">Existing Updates</h3>
            <ListGroup>
                {updates.map((update) => (
                    <ListGroup.Item key={update.id} className="d-flex justify-content-between align-items-center">
                        <div>
                            <strong>{new Date(update.date).toLocaleDateString()}</strong>
                            <p>{update.description}</p>
                        </div>
                        <Button variant="outline-primary" onClick={() => handleEdit(update.id)}>
                            Edit
                        </Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            {/* Modal for Editing Updates */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={editDate}
                                onChange={(e) => setEditDate(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={editDescription}
                                onChange={(e) => setEditDescription(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default AddUpdate;
