// src/components/Confirmation.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card, Modal, Alert, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { storage } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios';

const Confirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { donationData: initialDonationData, paymentMethod } = location.state || {};

    const [donationData, setDonationData] = useState(initialDonationData || {});
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [screenshot, setScreenshot] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        if (!initialDonationData || !paymentMethod) {
            console.error("Donation data or payment method is missing.");
        }
    }, [initialDonationData, paymentMethod]);

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]);
        setErrors((prevErrors) => ({ ...prevErrors, screenshot: '' })); // Clear previous error if any
    };

    const handleBack = () => {
        navigate('/payment-selection', { state: { donationData } });
    };

    const validateFields = () => {
        const fieldErrors = {};
        if (!confirmationNumber) fieldErrors.confirmationNumber = 'Confirmation number is required.';
        if (!screenshot) fieldErrors.screenshot = 'Screenshot is required.';
        setErrors(fieldErrors);
        return Object.keys(fieldErrors).length === 0;
    };

    const uploadScreenshotToStorage = async (file) => {
        const storageRef = ref(storage, `screenshots/${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };

    const handleConfirm = async () => {
        if (!validateFields()) return;

        try {
            const screenshotUrl = await uploadScreenshotToStorage(screenshot);

            const submissionData = {
                ...donationData,
                paymentMethod,
                confirmationNumber,
                screenshotUrl,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/donations`,
                submissionData
            );

            if (response.status === 200) {
                setShowDialog(true); // Show thank-you dialog on success
            }
        } catch (error) {
            console.error('Error submitting donation:', error);
            alert('Failed to submit donation Details.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDonationData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCloseDialog = () => {
        setShowDialog(false); // Close the dialog
        navigate('/'); // Redirect to home page
    };

    return (
        <Container className="py-5">
            <Button variant="secondary" onClick={handleBack}>
                Back to Payment Selection
            </Button>

            <h2 className="text-center mb-4">Confirm Donation</h2>

            <Alert variant="info">
                In case you need to correct any details, please use the back button to edit the data.
            </Alert>


            {paymentMethod === 'Internet Banking' && (
                <Alert
                    variant="info"
                    className="text-center"
                    style={{
                        backgroundColor: '#ffffff',
                        padding: '20px',
                        borderRadius: '8px',
                        marginBottom: '20px',
                    }}
                >
                    <h5>Internet Banking Details</h5>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                            lineHeight: '1.6',
                            textAlign: 'left',
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <strong>Account Holder:</strong> HANY MATHEW
                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => navigator.clipboard.writeText('HANY MATHEW')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>Account Number:</strong> 164001508208

                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => navigator.clipboard.writeText('164001508208')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>IFSC:</strong> ICIC0001640
                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => navigator.clipboard.writeText('ICIC0001640')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>VPA:</strong> hanymathew@icici
                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => navigator.clipboard.writeText('hanymathew@icici')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>Account Type:</strong> SAVING
                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => navigator.clipboard.writeText('SAVING')}
                            >
                                Copy
                            </Button>
                        </div>
                    </div>
                </Alert>
            )}


            {paymentMethod === 'PayPal' && (
                <Alert variant="warning" className="text-center">
                    If you encountered issues with PayPal payment, you can <a href="https://www.paypal.com/paypalme/HanyMathew" target="_blank" rel="noopener noreferrer">Click here to send</a> your donation manually.
                </Alert>
            )}
            {paymentMethod === 'Google Pay' && (
                <Alert variant="info" className="text-center">
                    <p>You selected Google Pay for your donation.</p>
                    <p>Scan the QR code below to complete your payment:</p>
                    <Image
                        src={`${process.env.PUBLIC_URL}/upi-qr-code.png`} // Replace with your QR code image path
                        alt="UPI QR Code"
                        style={{ width: '200px', height: '200px' }}
                        className="d-block mx-auto"
                    />
                    <p>After completing the payment, upload the screenshot and enter the confirmation number below.</p>
                </Alert>
            )}
            {paymentMethod === 'UPI' && (
                <Alert variant="info" className="text-center">
                    <p>You selected UPI for your donation.</p>
                    <p>Scan the QR code below to complete your payment:</p>
                    <Image
                        src={`${process.env.PUBLIC_URL}/upi-qr-code.png`} // Replace with your QR code image path
                        alt="UPI QR Code"
                        style={{ width: '200px', height: '200px' }}
                        className="d-block mx-auto"
                    />
                    <p>After completing the payment, upload the screenshot and enter the confirmation number below.</p>
                </Alert>
            )}

            {paymentMethod === 'Paytm' && (
                <Alert variant="info" className="text-center">
                    <p>You selected Paytm for your donation.</p>
                    <p>Scan the QR code below to complete your payment:</p>
                    <Image
                        src={`${process.env.PUBLIC_URL}/paytm-qr-code.png`} // Replace with your QR code image path
                        alt="Paytm QR Code"
                        style={{ width: '200px', height: '200px', marginBottom: '0' }} // Removed bottom margin
                        className="d-block mx-auto"
                    />
                    <div
                        style={{
                            backgroundColor: '#ffffff',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            display: 'inline-block',
                            textAlign: 'center',
                        }}
                    >
                        <p style={{ margin: '5px 0', fontSize: '12px' }}>Name: Hany Mathew</p>
                        <p style={{ margin: '5px 0', fontSize: '12px' }}>UPI Handle: 7011001050@ptyes</p>
                    </div>
                    <p>After completing the payment, upload the screenshot and enter the confirmation number below.</p>
                </Alert>
            )}

            <Card className="p-4">
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="name">
                                <Form.Label><strong>Name:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={donationData.name || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                            <Form.Group controlId="address" className="mt-3">
                                <Form.Label><strong>Address:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={donationData.address || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                            <Form.Group controlId="country" className="mt-3">
                                <Form.Label><strong>Country:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="country"
                                    value={donationData.country || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>

                            <Form.Group controlId="email" className="mt-3">
                                <Form.Label><strong>Email:</strong></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={donationData.email || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                            <Form.Group controlId="phone" className="mt-3">
                                <Form.Label><strong>Phone:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={donationData.phone || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="currency" className="mt-3">
                                <Form.Label><strong>Currency:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="currency"
                                    value={donationData.currency || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                            <Form.Group controlId="amount" className="mt-3">
                                <Form.Label><strong>Amount:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="amount"
                                    value={donationData.amount || ""}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                            <Form.Group controlId="message" className="mt-3">
                                <Form.Label><strong>Message:</strong></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    value={donationData.message || ""}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Do you want to show your message to the public?</Form.Label>
                                <div>
                                    <Form.Check
                                        type="radio"
                                        label="Show"
                                        name="isHiddenMessage"
                                        value="false"
                                        checked={!donationData.isHiddenMessage}
                                        onChange={() => setDonationData((prevData) => ({
                                            ...prevData,
                                            isHiddenMessage: false,
                                        }))}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Hide"
                                        name="isHiddenMessage"
                                        value="true"
                                        checked={donationData.isHiddenMessage}
                                        onChange={() => setDonationData((prevData) => ({
                                            ...prevData,
                                            isHiddenMessage: true,
                                        }))}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="paymentMethod" className="mt-3">
                                <Form.Label><strong>Payment Method:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={paymentMethod}
                                    readOnly
                                    style={{ backgroundColor: '#f0f0f0' }}
                                />
                            </Form.Group>
                            <Form.Group controlId="confirmationNumber" className="mt-3">
                                <Form.Label><strong>Confirmation Number:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={confirmationNumber}
                                    onChange={(e) => setConfirmationNumber(e.target.value)}
                                    placeholder="Enter confirmation number"
                                    required
                                />
                                {errors.confirmationNumber && (
                                    <Form.Text className="text-danger">{errors.confirmationNumber}</Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group controlId="screenshot" className="mt-3">
                                <Form.Label><strong>Upload Screenshot:</strong></Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    required
                                />
                                {errors.screenshot && (
                                    <Form.Text className="text-danger">{errors.screenshot}</Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="text-center mt-4">
                        <Col>
                            <Button variant="secondary" onClick={handleBack}>
                                Back to Payment Selection
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="success" onClick={handleConfirm}>
                                Confirm and Submit Donation Donation Details
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            {/* Thank You Modal */}
            <Modal show={showDialog} onHide={handleCloseDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your donation has been successfully submitted. 
                    You will be receiving an email with a handwritten letter from Jerome, shortly. 
                     Please check your email inbox, also the junk or trash folder! 
                     We deeply appreciate your support and generosity! God Bless you 🙏
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Confirmation;
