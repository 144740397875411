// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from "firebase/app"; // Import initializeApp
import { getStorage } from "firebase/storage"; // Import getStorage
const firebaseConfig = {
  apiKey: "AIzaSyBk4T43_awSbYK9aRlVF3UC70IrEtV58TM",
  authDomain: "react-java-project.firebaseapp.com",
  projectId: "react-java-project",
  storageBucket: "support-jerome.firebasestorage.app",
  messagingSenderId: "903056144261",
  appId: "1:903056144261:web:6fd6b89ab7c14b3cd9f6ec",
  measurementId: "G-LR4SEW9CT2"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

export { storage };
export default firebaseApp;