import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';
import Home from './components/Home';
import DonationForm from './components/DonationForm';
import Admin from './components/Admin';
import DonationView from './components/DonationView'; // Import DonationView component
import Login from './components/Login';
import Signup from './components/Signup';
import Header from './components/Header';
import Footer from './components/Footer';
import AddUpdate from './components/AddUpdate';
import PaymentSelection from './components/PaymentSelection';
import Confirmation from './components/Confirmation';
import PageNotFound from './components/PageNotFound'; // Import the custom 404 page component

function App() {
  const navigate = useNavigate();
  const [hiddenAdmin, setHiddenAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSignupSuccess = () => {
    navigate('/login'); // Redirect to login page after signup success
  };

  // Check admin login status
  useEffect(() => {
    const checkHiddenAdminStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/admin/check-hidden-admin`, {
          headers: { 'X-FORWARDED-FOR': await getIPAddress() }
        });
        setHiddenAdmin(response.data.hiddenAdmin);
      } catch (error) {
        console.error("Error checking admin login status:", error);
      }
    };

    checkHiddenAdminStatus();
  }, []);

  const getIPAddress = async () => {
    const response = await axios.get('https://api64.ipify.org?format=json');
    return response.data.ip;
  };

  return (
    <div>
      <Header hiddenAdmin={hiddenAdmin} isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/donate" element={<DonationForm />} />
        
        {/* New routes for payment process */}
        <Route path="/payment-selection" element={<PaymentSelection />} />
        <Route path="/confirmation" element={<Confirmation />} />

        {/* Protected admin routes */}
        <Route 
          path="/admin" 
          element={isLoggedIn ? <Admin /> : <Navigate to="/login" replace />} 
        />
        <Route 
          path="/donation-view" 
          element={isLoggedIn ? <DonationView /> : <Navigate to="/login" replace />} 
        />

        {/* Login and Signup routes */}
        <Route 
          path="/login" 
          element={<Login setIsLoggedIn={setIsLoggedIn} />} 
        />
        <Route path="/signup" element={<Signup onSignupSuccess={handleSignupSuccess} />} />

        {/* Protected AddUpdate route */}
        <Route 
          path="/add-update" 
          element={isLoggedIn ? <AddUpdate /> : <Navigate to="/login" replace />} 
        />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
