import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Row, Col, Image, Modal } from 'react-bootstrap';
import './PaymentSelection.css'; // Import the CSS file

const PaymentSelection = () => {
    const navigate = useNavigate();
    const { state } = useLocation(); // Retrieve donation data from state
    const { donationData } = state || {};
    const [selectedPayment, setSelectedPayment] = useState('');
    const [showPaypalModal, setShowPaypalModal] = useState(false);
    const [showUPIModal, setShowUPIModal] = useState(false);
    const [showUGPayModal, setShowGPayModal] = useState(false);
    const [showPaytmModal, setShowPaytmModal] = useState(false);
    const [showInternetBankingModal, setShowInternetBankingModal] = useState(false);

    const paytmDeepLink = 'paytmmp://pay?pa=your-paytm-upi-id@paytm&pn=YourName&am=100&cu=INR&tn=Donation';
    const paytmQRCode = `${process.env.PUBLIC_URL}/paytm-qr-code.png`; // Replace with your QR code path
    const upiQRCode = `${process.env.PUBLIC_URL}/upi-qr-code.png`; // Replace with your QR code path

    const paypalUrl =
        'https://www.paypal.com/signin?returnUri=https%3A%2F%2Fwww.paypal.com%2Fsignin%3FreturnUri%3Dhttps%253A%252F%252Fwww.paypal.com%252Fmyaccount%252Ftransfer%252Fhomepage%252Fexternal%252Fprofile%253FflowContextData%253DQ676kJ31GzACQhuHBWmNbR2IfjQ440RstCnZiXXqoM7hBByHnk7Hp67Fjj6DWdtpeOM-dqQQbS2-qLEWfWsA6wQGXxje356TrSOKshBTWtExI5G0nEyQYMdZrgZ49Ssnnj_rqBnnCYJmuB6w0nKtm8MHfX-koFWjWI1TS_bUKhSAV8FKsoOx44JoXOEWifHSg4H3C9iQjxWKKA-zByVBV_QzqAPXU8ING8jRCIWPV-Gj-UrUbRV_EuNkXjhfUeoWq_3AWsM6hOQbrwPSl40Tp95eDVdE4JJrD9OhlHBSiDexWSeMwEAWPBQwO-8QeUHeZ9WsVZF2VrODh72UYWxlLn_s4LzQQF60tnQedr3ZjJLEC-rRDfJ07XB2BMfN20leHlpwRaQcEE30oFRyDm2fdXlm7Tf57-BDAAEPTR9KyNL9YatWBpvZ290JpUZpGnjlJukqYXHUKMRyyO_CruUIFP_mhix9ESvtDfA3NXDUkMGQUpEXcYqR9i_3Fnng4QwSAwHIya4fgzZ55k1yYy7eVwLngSmdoGZHxAwbJ92FNFJBRXNtaclQJtSLYYtoxljCBGw9xVj421r9IkuW%26onboardData%3D%257B%2522country.x%2522%253A%2522US%2522%252C%2522locale.x%2522%253A%2522en_US%2522%252C%2522intent%2522%253A%2522paypalme%2522%252C%2522redirect_url%2522%253A%2522https%25253A%25252F%25252Fwww.paypal.com%25252Fmyaccount%25252Ftransfer%25252Fhomepage%25252Fexternal%25252Fprofile%25253FflowContextData%25253DywxKwsO4LHP_lBJQ8hi_AXaeK65x7_CDG5d-IqLZh5qST5shagERh0yhLNO3s1EghKKlWZkBMZ-HrxRoWVVjZiOOXAFGAuP_CGV2Hu9MCyqMCQf5RjELMbX5DLrpT7mW4iKZEwalAq-4VRcYvZqT_WvWqn2B4zHKA9uCGpxrbD0nIOimnqhxaSBZgxhkAEE4bGiMexq3Yp-76yjPlc27VPX6mCMGRduwVTuepyXlDI4kpvQ3InCB5GA3BQOJefdOkgzt4l8hfT_yjq8p7gJQB0nD5GMYswbzvtGaZSk0cArPKz-juvS6dEHo3ML5OJqXnV-Hs27oL1pXUgoPnRYDwk74oqwWivxjTIvchjozCr9nDZJN7NnsGEHGybx4l4bdfoeODX3vW8wrlycVpKWxbJT7_U3JiCkee2fmurwo--qUdOCq5s8x9gMhAd70PuqTOoKFeI5UjX71zoKQwqn18N6vkaKA0dJRmTxHTwwOfo-40Wc3yrgsoNSYTeO%2522%252C%2522sendMoneyText%2522%253A%2522You%252520are%252520sending%252520Hany%252520Mathew%2522%257D&ctxId=ul78707ee928574a0dae31d196b47a23a2';

    const upiUrl =
       'https://play.google.com/store/apps/details?id=com.phonepe.app';

    const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user';

    const handlePaymentSelect = (method) => {
        setSelectedPayment(method);

        if (method === 'PayPal') {
            setShowPaypalModal(true); // Show the PayPal modal
        } else if (method === 'UPI') {
            setShowUPIModal(true); // Show the UPI modal
        } else if (method === 'Paytm') {
            setShowPaytmModal(true); // Show the Paytm modal
        } else if (method === 'Internet Banking') {
            setShowInternetBankingModal(true); // Show Internet Banking modal
        } else if (method === 'Google Pay') {
            setShowGPayModal(true);
        }

    };

    // Update the Google Pay URL and redirection logic
    const handleGooglePayRedirect = () => {
        const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (isMobileDevice) {
            window.location.href = googlePlayUrl; // Redirect to Google Pay app on mobile
        } else {
            window.open("https://pay.google.com/", "_blank"); // Redirect to Google Pay website on desktop
        }
    };



    // Update the UPI URL for PhonePe redirection
    const handleUPIRedirect = () => {
        const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (isMobileDevice) {
            window.location.href = upiUrl; // Redirect to UPI app on mobile
        } else {
            window.open("https://www.phonepe.com/business-solutions/payment-gateway/", "_blank"); // Redirect to PhonePe website on desktop
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert(`Copied: ${text}`);
    };

    const handleProceedToConfirmation = () => {
        if (selectedPayment) {
            navigate('/confirmation', { state: { donationData, paymentMethod: selectedPayment } });
        }
        console.log(selectedPayment)
    };

    const handleConfirmPaypal = () => {
        setShowPaypalModal(false); // Close the modal
        window.open(paypalUrl, '_blank'); // Open PayPal in a new tab
        handleProceedToConfirmation(); // Navigate to Confirmation
    };

    return (
        <Container className="py-5">
            <Button variant="secondary" onClick={() => navigate("/donate")}>
                Back to Donation
            </Button>

            <h2 className="text-center mb-4">Select Payment Method</h2>

            <Row className="text-center mb-4">
                <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                    <Button
                        variant="outline-primary"
                        className="w-100 p-2 payment-button"
                        onClick={() => handlePaymentSelect('PayPal')}
                    >
                        <div>PayPal</div>
                        <Image
                            src={`${process.env.PUBLIC_URL}/paypal.png`}
                            alt="PayPal"
                            className="payment-image"
                        />
                    </Button>
                </Col>

                <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                    <Button
                        variant="outline-success"
                        className="w-100 p-2 payment-button"
                        onClick={() => handlePaymentSelect('UPI')}
                    >
                        <div>UPI</div>
                        <Image
                            src={`${process.env.PUBLIC_URL}/upi.png`}
                            alt="UPI"
                            className="payment-image"
                        />
                    </Button>
                </Col>

                <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                    <Button
                        variant="outline-info"
                        className="w-100 p-2 payment-button"
                        onClick={() => handlePaymentSelect('Google Pay')}
                    >
                        <div>Google Pay</div>
                        <Image
                            src={`${process.env.PUBLIC_URL}/googlepay.png`}
                            alt="Google Pay"
                            className="payment-image"
                        />
                    </Button>
                    {selectedPayment === 'Google Pay' && (
                        <div className="mt-2">
                            <p>You have selected Google Pay.</p>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    // Redirect logic for Google Pay
                                    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

                                    if (isMobileDevice) {
                                        // Open the Google Pay app via UPI deep link
                                        window.location.href = "upi://pay?pa=your-upi-id@bank&pn=YourName&am=100&cu=INR&tn=Donation";
                                    } else {
                                        // Redirect to the Google Pay website for desktop users
                                        window.open("https://pay.google.com/", "_blank");
                                    }

                                    handleProceedToConfirmation(); // Proceed to confirmation after redirection
                                }}
                            >
                                Continue with Google Pay
                            </Button>
                        </div>
                    )}
                </Col>

            </Row>

            <Row className="text-center mb-4">
                <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                    <Button
                        variant="outline-warning"
                        className="w-100 p-2 payment-button"
                        onClick={() => handlePaymentSelect('Paytm')}
                    >
                        <div>Paytm</div>
                        <Image
                            src={`${process.env.PUBLIC_URL}/paytm.png`}
                            alt="Paytm"
                            className="payment-image"
                        />
                    </Button>
                </Col>

                <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                    <Button
                        variant="outline-dark"
                        className="w-100 p-2 payment-button"
                        onClick={() => handlePaymentSelect('Internet Banking')}
                    >
                        <div>Internet Banking</div>
                        <Image
                            src={`${process.env.PUBLIC_URL}/internetbank.png`}
                            alt="Internet Banking"
                            className="payment-image"
                        />
                    </Button>
                </Col>
            </Row>

            {/* Internet Banking Modal */}
            <Modal show={showInternetBankingModal} onHide={() => setShowInternetBankingModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Internet Banking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                            lineHeight: '1.6',
                            textAlign: 'left',
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <strong>Account Holder:</strong> HANY MATHEW


                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => copyToClipboard('HANY MATHEW')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>Account Number:</strong> 164001508208

                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => copyToClipboard('164001508208')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>IFSC:</strong> ICIC0001640

                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => copyToClipboard('ICIC0001640')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>VPA:</strong> hanymathew@icici

                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => copyToClipboard('hanymathew@icici')}
                            >
                                Copy
                            </Button>
                        </div>
                        <div style={{ width: '100%' }}>
                            <strong>Account Type:</strong> SAVING
                            <Button
                                size="sm"
                                variant="light"
                                style={{ float: 'right', padding: '5px 10px', fontSize: '12px' }}
                                onClick={() => copyToClipboard('SAVING')}
                            >
                                Copy
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowInternetBankingModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleProceedToConfirmation()}>
                        Proceed with Internet Banking
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Paytm Modal */}
            <Modal show={showPaytmModal} onHide={() => setShowPaytmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with Paytm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        A new tab will open to proceed with the payment via Paytm. Alternatively, scan the QR code below to complete your payment.
                    </p>
                    <Image
                        src={paytmQRCode} // QR code for Paytm
                        alt="Paytm QR Code"
                        style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPaytmModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowPaytmModal(false); // Close the modal
                            window.open(paytmDeepLink, '_self'); // Open Paytm Deep Link
                            handleProceedToConfirmation();
                        }}
                    >
                        Yes, Continue with Paytm
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* UPI Modal */}

            <Modal show={showUPIModal} onHide={() => setShowUPIModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with UPI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        A new tab will open to proceed with the payment via UPI. If you are on a mobile device, it will open the PhonePe app directly. Otherwise, you will be redirected to the PhonePe website.
                    </p>
                    <Image
                        src={upiQRCode} // QR code for UPI
                        alt="UPI QR Code"
                        style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUPIModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowUPIModal(false); // Close the modal
                            handleUPIRedirect(); // Redirect based on device
                            handleProceedToConfirmation(); // Navigate to Confirmation page
                        }}
                    >
                        Yes, Continue with UPI
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Google PayModal */}
            <Modal show={showUGPayModal} onHide={() => setShowGPayModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with Google Pay</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        A new tab will open to proceed with the payment via Google Pay. If you are on a mobile device, it will open the Google Pay app directly. Otherwise, you will be redirected to the Google Pay website.
                    </p>
                    <Image
                        src={upiQRCode} // QR code or image for Google Pay
                        alt="Google Pay"
                        style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUPIModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowUPIModal(false); // Close the modal
                            handleGooglePayRedirect(); // Redirect based on device
                            handleProceedToConfirmation(); // Navigate to Confirmation page
                        }}
                    >
                        Yes, Continue with Google Pay
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* paypal Modal */}
            <Modal show={showPaypalModal} onHide={() => setShowPaypalModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proceed with PayPal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>A new tab will open to proceed with the payment on PayPal. Once completed, please return to the page and enter your confirmation details. Alternately, if you encounter any issues with PayPal payment, you can check the direct link on the next page. The QR Code will be displayed on the next page.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPaypalModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmPaypal}>
                        Yes, Continue with PayPal
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default PaymentSelection;
