import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import currencyCodes from "currency-codes";
import { useNavigate } from "react-router-dom";
import { CountryDropdown } from 'react-country-region-selector';


const DonationForm = () => {
  const [donationData, setDonationData] = useState({
    name: "",
    address: "",
    country: "", // Add country field here
    email: "",
    phone: "",
    amount: "",
    currency: "USD",
    message: "",
    isHiddenMessage: false,
  });

  const [errors, setErrors] = useState({});
  const [defaultCountryCode, setDefaultCountryCode] = useState("us");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const { country_name, country_code, currency } = response.data;

        // Set default country, currency, and phone country code from IP data
        setDonationData((prevData) => ({
          ...prevData,
          country: country_name || "", // Set country based on IP
          currency: currency || "USD" // Set currency based on IP
        }));

        setDefaultCountryCode(country_code ? country_code.toLowerCase() : "us"); // Set phone input default code
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocationData();
  }, []);


  const validateField = (name, value) => {
    let error = "";
    if (name === "name" && !value.trim()) error = "Name is required";
    if (name === "address" && !value.trim()) error = "Address is required";
    if (name === "country" && !value.trim()) error = "Country is required"; // Add country validation
    if (name === "email") {
        if (!value.trim()) {
            error = "Email is required";
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                error = "Invalid email format";
            }
        }
    }
    if (name === "phone" && !value) error = "Phone number is required";
    if (name === "amount" && (!value.trim() || isNaN(value) || Number(value) <= 0))
        error = "Valid amount required";
    return error;
};


  const handleCountryChange = (val) => {
    setDonationData((prevData) => ({
      ...prevData,
      country: val,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      country: validateField("country", val),
    }));
  };


  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
    }));
};


  const validateAllFields = () => {
    const newErrors = {};
    Object.keys(donationData).forEach((key) => {
      const error = validateField(key, donationData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDonationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const handlePhoneChange = (phone) => {
    setDonationData((prevData) => ({
      ...prevData,
      phone,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: validateField("phone", phone),
    }));
  };

  const handleContinueDonation = (e) => {
    e.preventDefault();
    if (validateAllFields()) {
      // Navigate to the payment selection page with donation data
      navigate("/payment-selection", { state: { donationData } });
    }
  };

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4">Donate Now</h2>
      <Form onSubmit={handleContinueDonation} className="p-3 border rounded bg-light">
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={donationData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={donationData.address}
                onChange={handleChange}
                placeholder="Enter your address"
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <CountryDropdown
                value={donationData.country}
                onChange={(val) => handleCountryChange(val)}
                classes={`form-control ${errors.country ? 'is-invalid' : ''}`}
              />
              {errors.country && (
                <div className="invalid-feedback d-block">{errors.country}</div>
              )}
            </Form.Group>
          </Col>
        </Row>




        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={donationData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <PhoneInput
                country={defaultCountryCode}
                value={donationData.phone}
                onChange={handlePhoneChange}
                inputProps={{ name: "phone", required: true }}
              />
              {errors.phone && <div className="text-danger mt-1">{errors.phone}</div>}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={4} md={2} className="order-1">
            <Form.Group>
              <Form.Label>Currency</Form.Label>
              <Form.Select name="currency" value={donationData.currency} onChange={handleChange}>
                {currencyCodes.codes().map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={8} md={4} className="order-2">
            <Form.Group>
              <Form.Label>Donation Amount</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={donationData.amount}
                onChange={handleChange}
                placeholder="Enter amount"
                isInvalid={!!errors.amount}
              />
              <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={donationData.message}
                onChange={handleChange}
                onBlur={handleBlur} // Add this line
                rows={3}
                placeholder="Write a message (optional)"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Do you want to show your message to the public?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Show"
                  name="isHiddenMessage"
                  value="false"
                  checked={!donationData.isHiddenMessage}
                  onChange={() => setDonationData((prevData) => ({ ...prevData, isHiddenMessage: false }))}
                />
                <Form.Check
                  type="radio"
                  label="Hide"
                  name="isHiddenMessage"
                  value="true"
                  checked={donationData.isHiddenMessage}
                  onChange={() => setDonationData((prevData) => ({ ...prevData, isHiddenMessage: true }))}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="text-center mt-4">
          <Col>
            <Button variant="success" type="submit" size="lg">
              Continue Donation Process
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default DonationForm;
