// src/components/Home.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Button, Modal, Collapse, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons


const Home = () => {
  const [isMalayalam, setIsMalayalam] = useState(false);
  const [homeContent, setHomeContent] = useState(null);
  const [messages, setMessages] = useState([]); // State to store messages
  const [updates, setUpdates] = useState([]); // Store updates data
  const [loadingUpdates, setLoadingUpdates] = useState(false); // Loading state for updates
  const [open, setOpen] = useState(false); // Collapse state for updates section

  const location = useLocation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const fetchUpdates = async () => {
    setLoadingUpdates(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/updates`);
      // Sort updates in chronological order by date
      const sortedUpdates = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setUpdates(sortedUpdates);
    } catch (error) {
      console.error("Error fetching updates:", error);
    } finally {
      setLoadingUpdates(false); // Ensure loading state is updated regardless of success or error
    }
  };

  const handleToggleUpdates = () => {
    if (!open && updates.length === 0) {
      fetchUpdates(); // Fetch updates only when first expanding if not already fetched
    }
    setOpen(!open); // Toggle open state
  };

  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the dialog if the state is passed

      // Clear the state to prevent dialog from showing on page reload
      navigate("/", { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleCloseDialog = () => setShowDialog(false);

  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the thank-you dialog
    }
    // Call fetchMessages within useEffect
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/donations`);
        // Filter for messages with isHiddenMessage set to false
        const filteredMessages = response.data.filter((message) => !message.isHiddenMessage);
        setMessages(filteredMessages); // Store the filtered messages in state
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages(); // Fetch messages when the component mounts or after a donation
  }, [location.state]);

  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the dialog if the state is passed
    }
  }, [location.state]);


  // Fetch home content data from backend
  useEffect(() => {
    const fetchHomeContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/home-content`);
        setHomeContent(response.data);
      } catch (error) {
        console.error("Error fetching home content:", error);
      }
    };

    fetchHomeContent();
  }, []);

  const toggleLanguage = () => {
    setIsMalayalam(!isMalayalam);
  };

  if (!homeContent) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ); // Show loading message while data is fetched
  }

  return (
    <Container className="py-5">

      <Row className="text-center mb-4">
        <Modal show={showDialog} onHide={handleCloseDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Thank You!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your donation has been successfully submitted. We deeply appreciate your support and generosity!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseDialog}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Col>
          <h1>{homeContent.headerText}</h1>
          <Row className="text-center">
            <Col>
              <Link to="/donate">
                <Button variant="primary" size="lg">
                  Donate Now
                </Button>
              </Link>
            </Col>
          </Row>
          <Button variant="link" onClick={toggleLanguage} className="mt-2">
            {isMalayalam ? "Read in English" : "മലയാളത്തിൽ വായിക്കുക"}
          </Button>
        </Col>
      </Row>

      {isMalayalam ? (
        // Malayalam Content
        <>
          <Row className="justify-content-center mb-4">
            <Col md={3} className="text-center">
              <img
                src={homeContent.imagePath1}
                alt="Jerome's Journey"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={8}>
              <p className="lead">{homeContent.malayalamParagraph1}</p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">{homeContent.malayalamParagraph2}</p>
            </Col>
            <Col md={3} className="text-center">
              <img
                src={homeContent.imagePath2}
                alt="Family Support"
                className="img-fluid rounded"
              />
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">{homeContent.malayalamParagraph3}</p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={4} className="text-center">
              <img
                src={homeContent.imagePath3}
                alt="Hope for Jerome"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={8}>
              <p className="lead">{homeContent.malayalamParagraph4}</p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">{homeContent.malayalamParagraph5}</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src={homeContent.imagePath4}
                alt="Family Support"
                className="img-fluid rounded"
              />
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">{homeContent.malayalamParagraph6}</p>

            </Col>
          </Row>
        </>
      ) : (
        // English Content  englishParagraph1
        <>
          <Row className="justify-content-center mb-4">
            <Col md={3} className="text-center">
              <img
                src={homeContent.imagePath1}
                alt="Jerome's Journey"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={8}>
              <p className="lead">{homeContent.englishParagraph1}</p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">{homeContent.englishParagraph2}</p>
            </Col>
            <Col md={3} className="text-center">
              <img
                src={homeContent.imagePath2}
                alt="Family Support"
                className="img-fluid rounded"
              />
            </Col>
          </Row>

          <Row className="justify-content-left mb-4" style={{ transform: "translateY(10px)" }}>
            <Col md={8}>
              <p className="lead">{homeContent.englishParagraph3}</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src={homeContent.imagePath3}
                alt="Hope for Jerome"
                className="img-fluid rounded"
              />
            </Col>
          </Row>


          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">
                {homeContent.englishParagraph4}
              </p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src={homeContent.imagePath4}
                alt="Family Support"
                className="img-fluid rounded"
              />
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <p className="lead">{homeContent.englishParagraph6}</p>

            </Col>
          </Row>
        </>
      )}

      {/* View Updates Button and Collapsible Section */}
      <Row className="mt-4">
        <Col className="text-start">
          <Button
            onClick={handleToggleUpdates}
            aria-controls="updates-collapse-text"
            aria-expanded={open}
            variant="outline-warning"
            className="d-flex align-items-center"
          >
            {open ? "Hide Updates" : "View Updates"}
            {open ? <FaChevronUp className="ms-2" /> : <FaChevronDown className="ms-2" />}
          </Button>
          <Collapse in={open}>
            <div id="updates-collapse-text" className="mt-3">
              {loadingUpdates ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                updates.map((update, index) => (
                  <div key={index} className="mb-3">
                    <strong>{new Date(update.date).toLocaleDateString()}</strong>
                    <p>{update.description}</p>
                  </div>
                ))
              )}
            </div>
          </Collapse>
        </Col>
      </Row>

      {/* Add margin-top to separate from View Updates section */}
      <Row className="text-center mt-3">
        <Col>
          <Link to="/donate">
            <Button variant="primary" size="lg">
              Donate Now
            </Button>
          </Link>
        </Col>
      </Row>

      <hr className="my-4" />
      <Row className="text-center">
        <Col>
          <div>
            <h3>Words of Support</h3>
            <p className="text-muted">Please donate to share words of support.</p>
          </div>

          <div className="text-start mt-4"> {/* Align messages to the left with some top margin */}
            {messages.length > 0 ? (
              messages.map((message, index) => (
                <div key={index} className="my-3">
                  <strong>{message.name}</strong>
                  <p>{message.message}</p>
                </div>
              ))
            ) : (
              <p>No messages to display yet. Be the first to show support!</p>
            )}
          </div>
        </Col>
      </Row>

    </Container>
  );
};

export default Home;
